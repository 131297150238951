
import { defineComponent, onBeforeMount, onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { MenuComponent } from "@/assets/ts/components";
import { ApiTaskManagement, ApiBase, ApiCampaign } from "@/core/api";
import ShowContentModel from "@/views/task-management/standard-operating-procedure/ShowContentModel.vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import mixin from "@/mixins";
import _ from "lodash";
import { useI18n } from "vue-i18n";
import {
  commonChangeDefaultDate,
  setModuleBCN,
} from "@/core/directive/function/common";

export default defineComponent({
  name: "call-out-records-information",
  components: {
    ShowContentModel,
  },
  setup() {
    const loading = ref<boolean>(false);
    const formRef = ref<null | HTMLFormElement>(null);
    const submitButton = ref<HTMLElement | null>(null);
    const deleteButton = ref<HTMLElement | null>(null);
    const route = useRoute();
    const router = useRouter();

    const {
      showValidateErrorMsg,
      showFormSubmitSuccessMsg,
      showServerErrorMsg,
    } = mixin();

    const { t } = useI18n();
    const formData = ref({
      id: route.params.id,
      task_owner: "",
      subject: [],
      contact: "",
      due_date: "",
      action_date: "",
      channel: "",
      importance: "",
      comment: "",
      comment_tagging: [],
      share_to: [],
      review_notice: "",
      created_at: null,
      updated_at: null,
      created_uid: "",
      updated_uid: "",
      task_source: "",
      task_source_data_id: "",
      __show: {
        created_user: "",
        updated_user: "",
      },
    });

    const rules = ref({
      task_owner: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      subject: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      contact: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      due_date: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      action_date: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      channel: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      importance: [
        {
          required: true,
          asyncValidator: (rule, value) => {
            return new Promise<void>((resolve, reject) => {
              if (value === "" || value === 0) {
                reject(t("common.isRequired"));
              } else {
                resolve();
              }
            });
          },

          trigger: "change",
        },
      ],
      task_source: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
    });

    const options = ref({
      sop: [],
      standard_script: [],
    });

    const showTitle = ref<string>("");
    const showContent = ref<string>("");

    const taskOwnerOptions = ref([]);
    const taskOwnerLoading = ref<boolean>(false);
    const subjectOptions = ref([]);
    const subjectLoading = ref<boolean>(false);
    const contactOptions = ref([]);
    const contactLoading = ref<boolean>(false);
    const commentTaggingOptions = ref([
      {
        label: "带货状态",
        options: [
          { label: "带货", value: "254" },
          { label: "很少带货", value: "255" },
          { label: "明确不带货", value: "256" },
          { label: "不知道", value: "257" },
        ],
      },
      {
        label: "带团状态",
        options: [
          { label: "还在带团", value: "259" },
          { label: "没有带团", value: "260" },
          { label: "明确已经转行不做导游", value: "261" },
          { label: "不是导游不带团但属于旅游业内", value: "262" },
          { label: "其他", value: "263" },
        ],
      },
      {
        label: "是否囤货",
        options: [
          { label: "囤货", value: "266" },
          { label: "不囤货", value: "267" },
        ],
      },
      {
        label: "分销对象",
        options: [
          { label: "直客", value: "269" },
          { label: "同行", value: "270" },
          { label: "平台", value: "271" },
        ],
      },
      {
        label: "商品熟知程度",
        options: [
          { label: "商品维度", value: "273" },
          { label: "品牌维度", value: "274" },
          { label: "品类维度", value: "275" },
        ],
      },
      {
        label: "是否接受港澳收货",
        options: [
          { label: "可港澳收货", value: "277" },
          { label: "仅境内收货", value: "278" },
        ],
      },
      {
        label: "微信触达标签",
        options: [
          { label: "咨询回复", value: "280" },
          { label: "触达询货", value: "281" },
          { label: "售后维护", value: "282" },
          { label: "触达成单", value: "283" },
        ],
      },
    ]);
    const commentTaggingLoading = ref<boolean>(false);
    const taskSourceTableData = ref([]);
    const campaignOptions = ref([]);
    const shareToOptions = ref([]);
    const shareToLoading = ref<boolean>(false);
    // SOP and Script
    const typeOptions = ref([]);
    const typeLoading = ref<boolean>(false);
    const applyToOptions = ref([]);
    const applyToLoading = ref<boolean>(false);
    const suitableConditionsOptions = ref([]);
    const suitableConditionsLoading = ref<boolean>(false);
    const suitableAccountOptions = ref([]);
    const suitableAccountLoading = ref<boolean>(false);

    const getSopStandardScriptInfo = async () => {
      const { data } = await ApiTaskManagement.getSopStandardScriptInfo({
        id: route.params.id,
        type: "callOut",
      });
      if (data.code == 0) {
        options.value.sop = data.data.sop;
        options.value.standard_script = data.data.standard_script;
      }
    };

    const getDropdownOptions = async () => {
      const { data } = await ApiBase.getTaggingData({
        short_key: ["call_out_subject", "sop_apply_to", "call_out_share_to"],
      });

      if (data.code == 0) {
        subjectOptions.value = data.data.call_out_subject.items;
        applyToOptions.value = data.data.sop_apply_to.items;
        shareToOptions.value = data.data.call_out_share_to.items;
      }
    };

    const getCampaignsSourceData = async () => {
      const { data } = await ApiCampaign.getCampaignsSourceData({
        max_item: "all",
      });
      if (data.code == 0) {
        campaignOptions.value = data.data;
      }
    };

    const switchUserType = (type: number, status: boolean) => {
      switch (type) {
        case 0:
          status
            ? (taskOwnerLoading.value = true)
            : (taskOwnerLoading.value = false);
          break;
        default:
          break;
      }
    };

    const switchUserValue = (type: number, data: any) => {
      switch (type) {
        case 0:
          taskOwnerOptions.value = data;
          break;
        default:
          break;
      }
    };

    const getUserSourceData = async (
      type: number,
      value?: string,
      id?: string
    ) => {
      switchUserType(type, true);
      let params =
        value != ""
          ? {
              search_value: value,
            }
          : {
              id: id,
            };

      const { data } = await ApiBase.getUserSourceData({
        ...params,
        status: 0,
      });
      switchUserType(type, false);
      if (data.code == 0) {
        switchUserValue(type, data.data);
      }
    };

    const debounceUserSearch = _.debounce(getUserSourceData, 1000);
    const searchTaskOwnerItems = (query) => {
      debounceUserSearch(0, query);
    };

    const switchAccountType = (type: number, status: boolean) => {
      switch (type) {
        case 0:
          status
            ? (contactLoading.value = true)
            : (contactLoading.value = false);
          break;
        default:
          break;
      }
    };

    const switchAccountValue = (type: number, data: any) => {
      switch (type) {
        case 0:
          contactOptions.value = data;
          break;
        default:
          break;
      }
    };

    const getAccountSourceData = async (
      type: number,
      value?: string,
      id?: string
    ) => {
      switchAccountType(type, true);
      let params =
        value != ""
          ? {
              search_value: value,
            }
          : {
              id: id,
            };
      const { data } = await ApiBase.getAccountSourceData(params);
      switchAccountType(type, false);
      if (data.code == 0) {
        switchAccountValue(type, data.data);
      }
    };
    const debounceAccountSearch = _.debounce(getAccountSourceData, 1000);
    const searchContactItems = (query) => {
      debounceAccountSearch(0, query);
    };

    const getCallOutRecordsInfo = async () => {
      loading.value = true;
      const { data } = await ApiTaskManagement.getCallOutRecordsInfo({
        id: route.params.id,
      });
      loading.value = false;
      if (data.code == 0) {
        formData.value = data.data;
        if (data.data.contact != "") {
          getAccountSourceData(0, "", data.data.contact);
        }
        if (data.data.task_owner != "") {
          getUserSourceData(0, "", data.data.task_owner);
        }
      }
    };

    const submit = () => {
      if (!formRef.value) {
        return;
      }

      formRef.value.validate((valid) => {
        if (valid) {
          loading.value = true;
          if (submitButton.value) {
            submitButton.value.setAttribute("data-kt-indicator", "on");
          }
          ApiTaskManagement.updateCallOutRecords(formData.value)
            .then(({ data }) => {
              loading.value = false;
              submitButton.value?.removeAttribute("data-kt-indicator");
              if (data.code == 0) {
                showFormSubmitSuccessMsg();
                getCallOutRecordsInfo();
              } else {
                showServerErrorMsg(data);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          showValidateErrorMsg();
          return false;
        }
      });
    };

    const deleteCallOutRecords = () => {
      Swal.fire({
        text: "Are you sure you would like to deactivate your Call Out Records?",
        icon: "warning",
        showCancelButton: true,
        buttonsStyling: false,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-danger",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          if (deleteButton.value) {
            deleteButton.value.setAttribute("data-kt-indicator", "on");
          }
          loading.value = true;
          ApiTaskManagement.deleteCallOutRecords({ id: route.params.id })
            .then(({ data }) => {
              loading.value = false;
              deleteButton.value?.removeAttribute("data-kt-indicator");
              if (data.code == 0) {
                Swal.fire({
                  text: "Your Call Out Records has been deactivated!",
                  icon: "success",
                  buttonsStyling: false,
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                }).then(() => {
                  router.push({ name: "call-out-records" });
                });
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      });
    };

    const showContentModel = (title: string, content: string) => {
      showTitle.value = title;
      showContent.value = content;
    };

    onBeforeMount(() => {
      getCallOutRecordsInfo();
      getDropdownOptions();
      getSopStandardScriptInfo();
      getCampaignsSourceData();
    });

    onMounted(() => {
      MenuComponent.reinitialization();
      setModuleBCN(t, route, router);
    });

    return {
      commonChangeDefaultDate,
      loading,
      formData,
      rules,
      options,
      formRef,
      submitButton,
      deleteButton,
      taskOwnerOptions,
      taskOwnerLoading,
      subjectOptions,
      subjectLoading,
      contactOptions,
      contactLoading,
      commentTaggingOptions,
      commentTaggingLoading,
      taskSourceTableData,
      campaignOptions,
      shareToOptions,
      shareToLoading,
      // SOP and Script
      typeOptions,
      typeLoading,
      applyToOptions,
      applyToLoading,
      suitableConditionsOptions,
      suitableConditionsLoading,
      suitableAccountOptions,
      suitableAccountLoading,
      showTitle,
      showContent,
      t,
      searchTaskOwnerItems,
      searchContactItems,
      submit,
      deleteCallOutRecords,
      showContentModel,
    };
  },
});
